import React, { useEffect } from "react"
import Layout from "../components/new/layout/layout"
import Container from "../components/new/container/container"
import MainHeader from "../components/new/mainHeader/mainHeader"
import SubHeader from "../components/new/subHeader/subHeader"
import Title from "../components/new/title/title"
import Button from "../components/new/button/button"
import TitleCategory from "../components/new/titleCategory/titleCategory"
import Block from "../components/new/block/block"
import BlockCategory from "../components/new/blockCategory/blockCategory"
import Paragraph from "../components/new/paragraph/paragraph"
import Flex from "../components/new/flex/flex"
import Grid from "../components/new/grid/grid"
import NodeGarden from "../components/new/nodeGarden/nodeGarden"
import FeaturesSelect from "../components/new/featuresSelect/featuresSelect"
import HeroImg from "../assets/images/hero-img.svg"
import Sample from "../assets/images/sample.svg"
import Features from "../assets/images/features.svg"
import Composable from "../assets/images/composable.svg"
import Puzzle from "../assets/images/puzzle.svg"
import Head from "../components/head"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function Home() {
    const data = useStaticQuery(graphql`
        query {
            allContentfulCustomer(filter: {featured:{eq: true}}, sort: {fields: createdAt, order: DESC}) {
                edges {
                    node {
                        logo
                        title
                        slug
                        featured
                        createdAt
                        url
                    }
                }
            }
        }
        `)
        
        React.useEffect(() => {
            const logoTrack = document.querySelector('.scrolling-logos');
            const logos = document.querySelectorAll('.about-logo');
            const logoCount = logos.length;
        
            // Duplicate the logos to create a seamless scroll
            for (let i = 0; i < logoCount; i++) {
              const clone = logos[i].cloneNode(true);
              logoTrack.appendChild(clone);
            }
          }, []);
        
          let counter = 0

    return(
        <Layout navbar="transparent">
            <Head
                title="Multi-vendor Dropshipping Automation Platform for Marketplaces"
                description="End-to-end automation software for centralizing marketplace operations. Import product catalogs, route orders, sync tracking and automate payments"
            />
            <Container style='fluid test-background-new'>
                <Container style='hero'>
                    {/* <NodeGarden /> */}
                    <Flex style='items-center z-index-up w-100 justify-content-around'>
                        <Block>
                            <MainHeader title='AUTOMATE YOUR</br><span class="blue">MULTI-VENDOR</span></br>MARKETPLACE' style='light' anim='slide-up'/>
                            <Paragraph text='Import product catalogs, route and sync orders, optimize shipping,</br>automate payments and manage returns. Seamlessly.' style='light w-100' anim='slide-right'/>
                            <Button href='/contacts/book-demo' text="Book Demo" style='btnOn dark-bg-alt mb-4' anim='slide-down'/>
                            <Button href='/contacts/get-pricing' text="Pricing" style='btnOn dark-bg ml-3 match-width' anim='slide-down'/>
                            <Flex style='mt-4 mb-3 align-items-center'>
                                <a href="https://www.g2.com/products/onport/reviews?utm_source=review-widget" title="Read reviews of Onport on G2" target='_blank' style={{display: 'block'}}>
                                    <img className="full-width desktop-only" style={{maxWidth: "140px", opacity:".6"}} alt="Read Onport reviews on G2" src="https://imgur.com/Q19Xvjp.png" />
                                </a>
                                <a href="https://www.g2.com/products/onport/reviews?utm_source=rewards-badge" target='_blank' className="ml-4">
                                    <img src="https://images.g2crowd.com/uploads/report_medal/image/1005139/medal.svg" style={{height:'80px'}}/>
                                </a>
                                <a href="https://www.g2.com/products/onport/reviews?utm_source=rewards-badge" target='_blank' className="ml-4">
                                    <img src="https://imgur.com/TSoXZtp.png" style={{height:'80px'}}/>
                                </a>
                            </Flex>
                            <Flex style='mt-4 align-items-center'>
                                <a href="https://www.capterra.com/p/197111/Onport/#reviews" title="Read reviews of Onport on Capterra" target='_blank' style={{display: 'block'}}><img className="full-width desktop-only" style={{maxWidth: "140px", opacity:".6"}} alt="Read Onport reviews on Capterra" src="https://imgur.com/Ven7FI1.png" /></a>
                                <a href="https://www.capterra.com/p/197111/Onport/reviews/" className="ml-4">
                                    <img border="0" src="https://brand-assets.capterra.com/badge/e9e7ce08-a6dd-4f43-ab1f-e5db95e022d5.svg" style={{height:'50px'}}/>
                                </a>
                            </Flex>
                        </Block>
                        <Block style='desktop-only'>
                            <img src='https://imgur.com/aeQsEOr.png' style={{height:'400px'}} data-sal='slide-left' data-sal-duration='600' loading="lazy"/>
                        </Block>
                    </Flex>
                </Container>
            </Container>
            <div className='fluid my-5 desktop-only overflow-hidden w-100'>
                <Grid style='scrolling-logos'>
                        {data.allContentfulCustomer.edges.map((edge) => {
                                return(
                                    <img src={`https://assets.onport.com/assets/brand-logos/${edge.node.slug}.png`} alt={edge.node.title} style={{opacity: '.7', width:'160px', maxHeight: '80px'}} loading="lazy"/>
                                )
                        })}
                    </Grid>
            </div>
            <Container style='pb-5 text-center' anim='slide-down'>
                <SubHeader title='Explore our Use Cases' style='dark'/>
            </Container>
            <Container style='fluid p-0'>
                <Grid style='grid-2 w-100 bg-lightgray'>
                    <div style={{backgroundImage: "url(https://imgur.com/ctam5l0.png)", backgroundSize: 'cover', width: '100%', height: '380px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                       <div className="use-case-text">
                            <TitleCategory title='JOIN THE MULTI-VENDOR MARKETPLACE REVOLUTION' style='lightBlue small' anim='slide-up'/>
                            <SubHeader title='Launch a New Marketplace' style='light small' anim='slide-up'/>
                            <Paragraph text='Build your marketplace quickly with next-gen composable tech by streamlining vendor onboarding to accelerate time to market' style='light l mb-4' anim='slide-up'/>
                            <Button href='/launch-your-new-marketplace-with-solid-foundations' text='Learn more ' style='btnOn transparent-bg' anim='slide-up' arrow='true'/>
                       </div>
                    </div>
                    <div className='use-case-quote' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
                        <Paragraph text='“With Onport, we were able to launch in less than three months, which we couldn’t have done had we built the system ourselves. We decided to implement a headless ecommerce platform to get to market fast at an acceptable cost.”' style='quote mb-4' anim='slide-up'/>
                        <img src='https://imgur.com/ogreG6q.png' style={{height: '64px', width: 'auto'}} loading="lazy"/>
                    </div>
                </Grid>
                <Grid style='grid-2 w-100 bg-lightgray'>
                    <div className='use-case-quote' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
                        <Paragraph text='“I think the main benefit of Onport is that it allows us to bring vendors onboard more quickly and expand our product assortment. We can bring new suppliers onboard faster, saving everybody much time.”' style='quote mb-4' anim='slide-up'/>
                        <img src='https://imgur.com/67E4xt1.png' style={{height: '64px', width: 'auto'}} loading="lazy"/>
                    </div>
                    <div style={{backgroundImage: "url(https://imgur.com/CV7TMRz.png)", backgroundSize: 'cover', width: '100%', height: '380px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div className="use-case-text">
                            <TitleCategory title='MAXIMIZE YOUR REVENUE WITH ZERO INVENTORY RISK' style='lightBlue small' anim='slide-up' />
                            <SubHeader title='Expand Product Catalog' style='light small' anim='slide-up'/>
                            <Paragraph text='Scale your online retail operations by selling more products to a broader customer base without extra stock and warehousing costs' style='light l mb-4' anim='slide-up'/>
                            <Button href='/expand-product-catalog-with-zero-inventory-risk' text='Learn more ' style='btnOn transparent-bg' anim='slide-up' arrow='true'/>
                       </div>
                    </div>
                </Grid>
                <Grid style='grid-2 w-100 bg-lightgray'>
                    <div style={{backgroundImage: "url(https://imgur.com/cdCbLsh.png)", backgroundSize: 'cover', width: '100%', height: '380px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div className="use-case-text">
                                <TitleCategory title='BOOST MARKET SHARE WITH INCREASED EFFICIENCY' style='lightBlue small' anim='slide-up'/>
                                <SubHeader title='Scale Marketplace Operations' style='light small' anim='slide-up'/>
                                <Paragraph text='Amplify your online business by building on top of your existing infrastructure without disruptions to outpace your competitors' style='light l mb-4' anim='slide-up'/>
                                <Button href='/scale-your-marketplace-efficiently-to-future-proof-your-business' text='Learn more ' style='btnOn transparent-bg' anim='slide-up' arrow='true'/>
                        </div>
                    </div>
                    <div className='use-case-quote' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
                        <Paragraph text='“Onport’s ability to automatically and manually connect to vendor’s inventory feeds has meant our team can focus on developing the platform’s user experience, adding features that benefit our clients directly while Onport handles the inventory syncing and order routing processes for us.”' style='quote mb-4' anim='slide-up'/>
                        <img src='https://imgur.com/KMueaHr.png' style={{height: '64px', width: 'auto'}} loading="lazy"/>
                    </div>
                </Grid>
            </Container>
            <Container style='pb-5 my-5 text-center'>
                <Container style='py-5 text-center' anim='slide-down'>
                    <TitleCategory title='ADAPTIVE'/>
                    <SubHeader title='A complete set of features to manage your online marketplace' style='dark'/>
                    <Paragraph text='Access a diverse range of integrated solutions, logistics planning, and vast API library to manage your backend marketplace operations from a single platform' style='w-75 mx-auto'/>
                </Container>
                <Grid style='grid-2 position-relative'>
                    <Block anim='slide-up'>
                        <FeaturesSelect/>
                    </Block>
                    <Block style=''>
                        <img src={Features} className='desktop-only' style={{height:'360px', position: 'absolute', top:'60px', right:'0' }} data-sal='slide-left' data-sal-duration='600' loading="lazy"/>
                    </Block>
                </Grid>
                <Button href='/features' text="Explore All" style='btnOn light-bg'/>
            </Container>
            <Container style='mb-5'>
                <Grid style='grid-2'>
                    <Block style=''>
                        <img src={Sample} className='home-on-img' data-sal='slide -right' data-sal-duration='600' loading="lazy"/>
                    </Block>
                    <Block anim='slide-left'>
                        <TitleCategory title='PARTNERS'/>
                        <SubHeader title='Integrate directly into your existing workflow' style='dark'/>
                        <Paragraph text='Onport has partnered with industry leading eCommerce solution platforms to communicate and unify your marketplace operations.<br/><br/>Here are a few of the integrations we’ve built so you don’t have to.' style='my-3'/>
                        <Button href='/partners' text='Learn more' style='inline-text'/>
                        <Grid style='grid-5 w-100 mt-3'>
                            <Link to={"/partners/automation/intercom"} ><img src={require(`../assets/icons/platform/round/intercom.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-shopify"} ><img src={require(`../assets/icons/platform/round/shopify.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-magento"} ><img src={require(`../assets/icons/platform/round/magento.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/shipping/shippypro"} ><img src={require(`../assets/icons/platform/round/shippypro.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-3d-cart"} ><img src={require(`../assets/icons/platform/round/3dcart.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-etsy"} ><img src={require(`../assets/icons/platform/round/etsy.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-prestashop"} ><img src={require(`../assets/icons/platform/round/prestashop.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/store/dropship-woocommerce"} ><img src={require(`../assets/icons/platform/round/woocommerce.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/store/bigcommerce"} ><img src={require(`../assets/icons/platform/round/bigcommerce.svg`)} style={{height: '64px'}}/></Link>
                            <Link to={"/partners/dropship/dropship-tradegecko"} ><img src={require(`../assets/icons/platform/round/tradegecko.svg`)} style={{height: '64px'}}/></Link>
                        </Grid>  
                    </Block>
                </Grid>
            </Container>
            <Container style='mt-2'>
                <Grid style='grid-2'>
                    <Block anim='slide-up'>
                        <TitleCategory title='INFRASTRUCTURE'/>
                        <SubHeader title='Scale with confidence,</br>Gain complete flexibility' style='dark'/>
                        <Paragraph text='Experience all-in-one inventory, orders, payments, and shipping management software with Onport. Are you ready to take ownership of the multi-vendor ecosystem?'/>
                    </Block>
                    <Block style=''>
                        <img src={Sample} className='my-5 home-on-img' data-sal='slide-left' data-sal-duration='600' loading="lazy"/>
                    </Block>
                </Grid>
                {/* <Grid style='grid-4 mt-5'>
                    <BlockCategory anim='slide-up' delay='200'
                        title='Inventory Syncing'
                        text='Seamlessly upload items to the Marketplace, while keeping inventory levels and pricing in sync' 
                        icon='https://assets.jetti.io/assets/icons/onport/inventory.svg'/>
                    <BlockCategory anim='slide-up' delay='400'
                        title='Order Routing'
                        text='Automatically split an order into the different fulfilment flows between Vendors, In-House or 3PL' 
                        icon='https://assets.jetti.io/assets/icons/onport/routing.svg'/>
                    <BlockCategory anim='slide-up' delay='600'
                        title='Shipping Workflows'
                        text='Define a fully flexible network of Carriers, customer shipping rates, and shipping cost allocation' 
                        icon='https://assets.jetti.io/assets/icons/onport/shipping.svg'/>
                    <BlockCategory anim='slide-up' delay='800'
                        title='Payments Automation'
                        text='Automatically calculate commission rates for all items sold and import cost prices in batches from vendors' 
                        icon='https://assets.jetti.io/assets/icons/onport/rules.svg'/>
                </Grid> */}
            </Container>
            <Container style='fluid brandDark'>
                <Container style='py-5 my-5 text-center'>
                    <TitleCategory title='ADAPTIVE TECH' style='lightBlue'/>
                    <SubHeader title='Agility for your solution' style='light'/>
                    <Paragraph text='Experience all-in-one inventory, orders, payments, and shipping management software with Onport. Are you ready to take ownership of the digital space?' style='light w-75 mx-auto'/>
                    <Grid style='grid-2'>
                    <Block>
                        <Container style='option mt-5 brandDarker' anim='slide-up'>
                            <img src={Puzzle} style={{height:'200px'}} className='my-5'loading="lazy"/>
                            <Title title='Pre built best in class' style='text-light'/>
                            <Paragraph text='Driven by intent, we work alongside you and your vendor partners to help you scale your business and flex as you evolve' style='light w-75 mx-auto'/>
                            <Button href='/contacts/lets-talk' text="Let's Talk" style='btnOn dark-bg'/>
                        </Container>
                    </Block>
                    <Block>
                        <Container style='option mt-5 brand' anim='slide-down'>
                            <img src={Composable} style={{height:'200px'}} className='my-5' loading="lazy"/>
                            <Title title='Composable' style='text-light'/>
                            <Paragraph text='Deeply entrenched in your business, our composable tech grows as you grow. Onport’s API-first approach prioritizes versatility.' style='light w-75 mx-auto'/>
                            <Button href='/contacts/lets-talk' text="Let's Talk" style='btnOn brand-bg'/>
                        </Container>
                    </Block>
                </Grid>
                </Container>
            </Container>
            <Container style='my-5 text-center' anim='fade'>
                <TitleCategory title='CLIENTS' style='lightBlue'/>
                <SubHeader title='Committed to paving the</br>way for your success'/>
                <Paragraph text='Technology and specialized expertise that our trusted customers need to thrive' style='w-75 mx-auto'/>
                <Grid style='grid-4 mt-5'>
                    <Block style=''>
                        <SubHeader title='35<span class="lightBlue">+</span>'/>
                        <Paragraph text='Pre built<br/>integrations'/>
                    </Block>
                    <Block style=''>
                        <SubHeader title='500<span class="lightBlue">+</span>'/>
                        <Paragraph text='Customers</br>to date'/>
                    </Block>
                    <Block style=''>
                        <SubHeader title='3.000<span class="lightBlue">+</span>'/>
                        <Paragraph text='API<br/>endpoints'/>
                    </Block>
                    <Block style=''>
                        <SubHeader title='30.000<span class="lightBlue">+</span>'/>
                        <Paragraph text='Third party<br/>sellers'/>
                    </Block>
                </Grid>
                <Grid style='grid-6 mt-5 desktop-only'>

                    {data.allContentfulCustomer.edges.map((edge) => {
                        if (edge.node.featured && counter < 6) {
                            counter += 1
                            return(
                                <a href={edge.node.url} target="_blank">
                                    <img src={`https://assets.onport.com/assets/brand-logos/${edge.node.slug}.png`} className="about-logo about-logo__home-alt" alt={edge.node.title} style={{opacity: '.6', width:'120px', height: 'auto'}} loading="lazy"/>
                                </a>
                            )
                        }
                    })}
                </Grid>
            </Container>
        </Layout>

    )
}
